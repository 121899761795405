import React from "react"
import Hours from 'components/hours'

export default ({ 
    title, 
    copy, 
    hours, 
    featureColour, 
    children,
    button
}) => {
    return (
        <div className="titleWrapper container">
            <div className={ "container bg " + featureColour }>
                { button &&
                    <div className={'button-container'}>
                        { button }
                    </div>
                }
                <div className="titleWrapper-text">
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                    <div dangerouslySetInnerHTML={{ __html: copy }} />
                    <Hours hours={ hours } />
                </div>
                { children }
            </div>
        </div>
    )
}
