import React from "react"

export default ({ hours }) => {
    const date = new Date()
    const weekday = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const dayOfWeek = weekday[date.getDay()]

    if (!hours) {
        return null
    }

    return (
        hours.map((row, index) => {
            const {
                day,
                hours
            } = row

            if (day === dayOfWeek) {
                return (
                    <div className='hours' key={index}>
                        <span>
                            { hours }
                        </span>
                    </div>

                )
            } else {
                return null
            }

        })
    )
}
