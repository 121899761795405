import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "components/seo"
import WhatsOnSingleMain from "components/whatsOnSingleMain"
import BlogGrid from "components/blogGrid"
import MoreEventsSlideshow from 'components/moreEventsSlideshow'
import { useIsMobile } from 'utils'

export default ({ data, location }) => {
    
    const {
      title,
      slug,
      content,
    } = data.siteFields.page

    const isMobile = useIsMobile()

    let excerpt = content ? content.replace(/(<([^>]+)>)/ig,"") : ''
    excerpt = excerpt ? excerpt.slice(0,160) : ''
    if (excerpt.length === 160) {
        excerpt = excerpt + '...'
    }

	return (
        <Layout location={ location }>
            <SEO 
                title={ title }
                description={excerpt}
            />
            <WhatsOnSingleMain data={ data } />
            { isMobile ?
                <div className="DirectorySlideshow scrolled-into-view">
                    <MoreEventsSlideshow
                        title="<h1>More events <em>at Pentridge</em></h1>" 
                        featureColour="lightBlue"
                        excludeSlug={slug}
                    />
                </div>
            :
                <div className="BlogGrid scrolled-into-view">
                    <BlogGrid 
                        title="<h1>More events <em>at Pentridge</em></h1>" 
                        featureColour="lightBlue" 
                        type="whatsOn"
                        excludeSlug={slug}
                    />
                </div>
            }
        </Layout>
	)
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                slug
                date
                content(format: RENDERED)
                featuredImage {
                    node {
                        altText
                        title(format: RENDERED)
                        mediaItemUrl
                        slug
                    }
                }
            }
            event(id: $databaseId, idType: DATABASE_ID) {
                title
                slug
                eventFields {
                    date
                    displayDate
                    location
                    cost
                    phoneNumber
                    emailAddress
                    website {
                        title
                        url
                    }
                    eventId
                    eventLink
                    buttonTitle
                    excerpt
                    slideshowImage {
                        mediaItemUrl
                    }
                }
                types {
                    edges {
                        node {
                            name
                        }
                    }
                }
            }
        }
    }
`
