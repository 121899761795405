import React from "react"
import { Date } from 'utils'
import { EventType } from 'utils'
import Link from 'components/link'

const EventbriteButton = typeof window !== "undefined" ? require("react-eventbrite-popup-checkout").default : null

export default ({ data }) => {

    const {
        siteFields
    } = data

    const page = siteFields.page
    const event = siteFields.event

    const featuredImage = page.featuredImage

    const formattedDate = event.eventFields.displayDate ? event.eventFields.displayDate : event.eventFields.date ? <Date date={event.eventFields.date} includeYear="true" /> : null

	return (

        <div className="whatsOn-single-main">

            <div className="container">

                <div className="whatsOn-single-intro">

                    <Link to='/whats-on' className="button back">
                        Back to all
                    </Link>

                    <div>

                        <EventType name={ ( event.types && event.types.edges.length ) ? event.types.edges[0].node.name : 'Event' } />

                    </div>

                    <h1 dangerouslySetInnerHTML={{ __html: page.title }} />
            
                    {
                        featuredImage ?
                        
                            <img src={ featuredImage.node.mediaItemUrl } alt={ page.title } />

                        : ''
                    }

                    <div className="whatsOn-single-details-content" dangerouslySetInnerHTML={{ __html: page.content }} />

                </div>

                <div className="whatsOn-single-details">

                    { formattedDate &&

                        <div>

                            <h3>When</h3>

                            <div dangerouslySetInnerHTML={{ __html: formattedDate }} />

                        </div>
                    
                    }

                    { event.eventFields.location ?

                        <div>

                            <h3>Where</h3>

                            <div dangerouslySetInnerHTML={{ __html: event.eventFields.location }} />

                        </div>
                    
                    : '' }

                    { event.eventFields.cost ?

                        <div>

                            <h3>Cost</h3>

                            <div dangerouslySetInnerHTML={{ __html: event.eventFields.cost }} />

                        </div>
                    
                    : '' }

                    { event.eventFields.phoneNumber || event.eventFields.emailAddress || event.eventFields.website ?

                        <div>

                            <h3>Contact Details</h3>

                            <a href={ 'tel:' + event.eventFields.phoneNumber } dangerouslySetInnerHTML={{ __html: event.eventFields.phoneNumber }} />
                            <a href={ 'mailto:' + event.eventFields.emailAddress } dangerouslySetInnerHTML={{ __html: event.eventFields.emailAddress }} />
                            { event.eventFields.website ? <a href={ event.eventFields.website.url } dangerouslySetInnerHTML={{ __html: event.eventFields.website.title ? event.eventFields.website.title : event.eventFields.website.url }} /> : '' }

                        </div>
                    
                    : '' }
                    {EventbriteButton !== null && event.eventFields.eventId &&
                        <EventbriteButton 
                            ebEventId={event.eventFields.eventId} 
                            className='button border book-now'>
                                {event.eventFields.buttonTitle ? event.eventFields.buttonTitle : 'Book now'}
                        </EventbriteButton>
                    }
                </div>


            </div>
            
        </div>

	)
}
