import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from 'components/link'
import TitleWrapper from 'components/titleWrapper'
import { EventType, Date } from 'utils'

export default ({
    title,
    columns,
    featureColour,
    backgroundColour,
    type,
    excludeSlug,
    hide,
}) => {
    const data = useStaticQuery(graphql`
        query BlogQuery {
            siteFields {
                posts {
                    edges {
                        node {
                            slug
                            title
                            featuredImage {
                                node {
                                    mediaItemUrl
                                }
                            }
                            categories {
                                nodes {
                                    name
                                }
                            }
                        }
                    }
                }
                events {
                    edges {
                        node {
                            title
                            link
                            slug
                            date
                            featuredImage {
                                node {
                                    mediaItemUrl
                                }
                            }
                            eventFields {
                                date
                                displayDate
                                excerpt
                                slideshowImage {
                                    mediaItemUrl
                                }
                            }
                            types {
                                edges {
                                    node {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const linkSlug = type === 'whatsOn' ? '/whats-on/' : '/news/'
    let posts = type === 'whatsOn' ? data.siteFields.events.edges : data.siteFields.posts.edges


    if (excludeSlug) {
        posts = posts.filter(post => {
            return !(post.node.slug === excludeSlug)
        })
    }
    posts = posts.slice(0, 3)

    if (hide) {
        return null
    }

    return (
        <div className={backgroundColour ? 'BlogGrid-cont ' + backgroundColour : 'BlogGrid-cont'}>
            <TitleWrapper
                title={title}
                featureColour={featureColour}
                link={{
                    label: 'View More',
                    url: linkSlug
                }}
            >
                <ul className={columns}>
                    {posts.map((row, index) => {
                        const {
                            featuredImage,
                            title,
                            slug,
                            types,
                            categories,
                            eventFields,
                        } = row.node

                        const formattedDate = eventFields.displayDate ? eventFields.displayDate : eventFields.date ? <Date date={eventFields.date} includeYear="true" /> : null

                        return (
                            <li key={index}>
                                <Link to={linkSlug + slug}>
                                    { ( type === 'whatsOn' && formattedDate ) &&
                                        <div className="newsItem-date" dangerouslySetInnerHTML={{ __html: formattedDate }} />
                                    }
                                    <div className="BlogGrid-image-cont">
                                        {featuredImage && <div className={ "BlogGrid-image " + type } style={{ backgroundImage: `url(${featuredImage.node.mediaItemUrl})` }} /> }
                                    </div>
                                    <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                    <div className="button">
                                        View { type === 'whatsOn' ? <EventType name={ ( types && types.edges.length ) ? types.edges[0].node.name : 'Event' } /> : categories.nodes[0].name }
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </TitleWrapper>
        </div>
    )
}
