import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import TitleWrapper from 'components/titleWrapper'
import Slider from 'components/slider'
import Link from 'components/link'

export default ({
    title,
    featureColour,
    backgroundColour,
    excludeSlug,
}) => {
    const data = useStaticQuery(graphql`
        query MoreEvents {
            siteFields {
                events {
                    edges {
                        node {
                            title
                            link
                            slug
                            date
                            featuredImage {
                                node {
                                    mediaItemUrl
                                }
                            }
                            eventFields {
                                date
                                displayDate
                                excerpt
                                slideshowImage {
                                    mediaItemUrl
                                }
                            }
                            types {
                                edges {
                                    node {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    let events = data.siteFields.events.edges

    if (excludeSlug) {
        events = events.filter(event => {
            return !(event.node.slug === excludeSlug)
        })
    }
    events = events.slice(0, 3)

    const sliderRef = useRef(null)

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: false,
        pageDots: true,
        cellAlign: 'left',
        autoPlay: false
    }

    useEffect(() => {
        setTimeout(() => {
            sliderRef.current.resize()
        }, 600)
    }, [])

    return (
        <div className={`DirectorySlideshow-cont whatsOn ${backgroundColour || ''}`}>
            <TitleWrapper
                title={title}
                featureColour={featureColour}
                button={
                    <Link
                        to={`/whats-on`}
                        dangerouslySetInnerHTML={{ __html: `What's On` }}
                        className="button lowercase"
                    />
                }
            >
                <Slider
                    className={ 'DirectorySlideshow-slideshow dots' }
                    options={flickityOptions}
                    flickityRef={ref => sliderRef.current = ref}
                >
                    {(events).map((row, index) => {
                        return (
                            <div className={ 'DirectorySlideshow-item whatsOn' } key={index}>
                                {(() => {
                                        const {
                                            title,
                                            slug,
                                            featuredImage,
                                        } = row.node

                                        return (
                                            <Link 
                                                to={'/whats-on/' + slug}
                                                key={index} 
                                                className={'directoryItem'}
                                            >
                                                <div className="directoryItem-image-cont">
                                                    <div className="bg" style={{
                                                        backgroundImage: `url(${ featuredImage && featuredImage.node.mediaItemUrl })`
                                                    }} />
                                                    <div className="directoryItem-image ">
                                                        <div className="directoryItem-logo" style={{

                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="directoryItem-text">
                                                    <div className={ 'directoryItem-title-item whatsOn' }>
                                                        <h3 dangerouslySetInnerHTML={{ __html: title }} />
                                                        <span className={'button'}>
                                                            View event
                                                        </span>
                                                    </div>
                                                </div>
                                            </Link>
                                        )

                                    })()
                                }
                            </div>
                        )
                    })}
                </Slider>
            </TitleWrapper>
        </div>
    )
}
